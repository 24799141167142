<template>
  <router-link :to="/transaction/ + transactionInformation.id">
    <div class="transactionCart row">
      <div class="col-4">
        <div class="transactionCart_code">
          {{ transactionInformation.appointment_code }}
        </div>
        <div class="transactionCart_employeeName">
          {{
            `${transactionInformation.employee_first_name} ${transactionInformation.employee_last_name}`
          }}
        </div>
      </div>
      <div class="col-3">
        <div class="transactionCart_position">
          {{ transactionInformation.service_name }}
        </div>
        <div class="transactionCart_expertName">
          <short-name
            :name="
              transactionInformation.expert_first_name +
              ' ' +
              transactionInformation.expert_last_name
            "
          />
        </div>
      </div>
      <div class="col-3">
        <div class="transactionCart_time">
          <data-formatted
            date-format="HH:mm"
            :data="transactionInformation.payment_date"
          />
        </div>
        <div class="transactionCart_date">
          <data-formatted
            date-format="dd MMM yyyy"
            :data="transactionInformation.payment_date"
          />
        </div>
      </div>
      <div class="col-1">
        <div class="transactionCart_coast">
          €{{ transactionInformation.price }}
        </div>
        <div class="transactionCart_status">
          {{ transactionInformation.status }}
        </div>
      </div>
      <div class="col-1 text-end">
        <arrow height="16px" width="16px" />
      </div>
    </div>
  </router-link>
</template>

<script>
import ArrowThink from "@/components/UI/icons/arrows/ArrowThink";
import Arrow from "@/components/UI/icons/arrows/Arrow";
import DataDay from "@/components/components-helpers/DataDay";
import ShortName from "@/components/components-helpers/ShortName";
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  props: {
    transactionInformation: Object,
  },
  name: "TransactionCart",
  components: { DataFormatted, ShortName, DataDay, Arrow, ArrowThink },
};
</script>

<style></style>
