<template>
  <div class="row companyTransactionCart">
    <div class="col-3 companyTransactionCart__time">
      <div>
        <data-formatted
          date-format="HH:mm"
          :data="
            !!transaction.date_paid
              ? transaction.date_paid
              : transaction.planned_date
          "
        />
      </div>
      <div>
        <data-formatted
          date-format="dd MMM yyyy"
          :data="
            !!transaction.date_paid
              ? transaction.date_paid
              : transaction.planned_date
          "
        />
      </div>
    </div>
    <div class="col-3 companyTransactionCart__coast">
      <div>€{{ transaction.payment.appointments_coverage }}</div>
      <div>€{{ transaction.payment.subscription_price }}</div>
    </div>
    <div class="offset-1 col-2 companyTransactionCart__totalCoast">
      €{{ transaction.payment.total }}
    </div>
    <div class="offset-1 col-2">
      {{ transaction.status.name }}
    </div>
  </div>
</template>

<script>
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  props: {
    transaction: {},
  },
  name: "CompanyTransactionCart",
  components: { DataFormatted },
};
</script>

<style></style>
