<template>
  <main-layout>
    <transactions-admin-page v-if="loginUser.role_id === 1" />
    <transactions-company-page v-if="loginUser.role_id === 3" />
    <transactions-employee-page v-if="loginUser.role_id === 4" />
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import TransactionCart from "@/components/elements/transactions/TransactionCart";
import TransactionsCartCompany from "@/components/elements/transactions/TransactionsCartCompany";
import TransactionsAdminPage from "@/components/pages-templates/transactions/TransactionsAdminPage";
import { mapGetters } from "vuex";
import TransactionsEmployeePage from "@/components/pages-templates/transactions/TransactionsEmployeePage";
import TransactionsCompanyPage from "@/components/pages-templates/transactions/TransactionsCompanyPage";

export default {
  name: "TransactionsPage",
  components: {
    TransactionsCompanyPage,
    TransactionsEmployeePage,
    TransactionsAdminPage,
    TransactionsCartCompany,
    TransactionCart,
    MainLayout,
  },
  data() {
    return {
      transactions: {
        employee: {},
        companies: {},
      },
      employees: true,
      companies: false,
    };
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
