<template>
  <h1>{{ $t("sidebarTransactionsBtn") }}</h1>
  <div class="servicesPage__filter">{{ $t("filterBy") }}</div>
  <div class="servicesPage__filterWrapper">
    <vue-multiselect
      class="servicesPage__select"
      :placeholder="$t('allService')"
      label="name"
      :show-labels="false"
      v-model="filterByServices"
      :options="servicesOptions"
    >
    </vue-multiselect>
    <base-button
      class="ml-25"
      @click="filterBy('currentMonth')"
      :active="filter.currentMonth"
      :sort="true"
    >
      {{ $t("currentMonth") }}
    </base-button>
    <datepicker
      :locale="loginUser.language_code"
      :class="{ 'datepickerInputFilterClass-active': filter.customData }"
      :enableTimePicker="false"
      inputClassName="datepickerInputFilterClass"
      class="datepickerFilterClass"
      v-model="statisticDataRange"
      @closed="setCustomDateRange()"
      format="dd/MM"
      :placeholder="$t('custom')"
      autoApply
      range
      utc
    />
    <div class="col-2 experts__filterReset" @click="resetFilter()">
      <reset-icon />
      {{ $t("resetFilter") }}
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-xxl-6">
      <div
        class="employeeTransactions__statistics employeeTransactions__statistics-gradient"
      >
        <h5>
          <data-formatted
            v-if="thisMonth"
            date-format="dd"
            :data="statisticData.start"
          />
          <data-formatted
            v-else
            date-format="dd MMM"
            :data="statisticData.start"
          />
          -
          <data-formatted
            date-format="dd MMM, yyyy:"
            :data="statisticData.end"
          />
        </h5>
        <div
          v-if="statistics.current"
          class="d-flex align-items-center justify-content-between"
        >
          <div>
            {{ statistics.current.pastCount }}
            <span>/{{ `${statistics.current.totalCount} ` }}</span>
            <small>{{ $t("passedAppointment") }}</small>
          </div>
          <div>
            €{{ statistics.current.amount }} <small>{{ $t("spent") }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-xxl-6">
      <div class="employeeTransactions__statistics">
        <h5>
          {{ $t("estimateFor") }}
          <data-formatted
            v-if="thisMonth"
            date-format="MMM, yyyy:"
            :data="statisticData.start"
          />
          <span v-else>
            <data-formatted date-format="dd MMM" :data="statisticData.start" />
            -
            <data-formatted
              date-format="dd MMM, yyyy:"
              :data="statisticData.end"
            />
          </span>
        </h5>
        <div
          v-if="statistics.estimate"
          class="d-flex align-items-center justify-content-between"
        >
          <div>
            {{ statistics.estimate.count }}
            <small>{{ $t("appointments") }}</small>
          </div>
          <div>
            €{{ statistics.estimate.amount }} <small>{{ $t("spent") }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <h2 v-if="showTransactions" class="mb-0">{{ $t("appointments") }}</h2>
      <div
        @click="showTransactions = !showTransactions"
        class="employeeTransactions__showAppointments"
        :class="{ 'ms-15': showTransactions }"
      >
        <div
          class="transition"
          :class="{ 'serviceSinglePage__read-icon': showTransactions }"
        >
          <horizontal-arrow :appointment-icon="true" />
        </div>
        {{ showTransactions ? $t("hide") : $t("showAppointment") }}
      </div>
    </div>
  </div>
  <div v-if="showTransactions">
    <div>
      <div class="appointmentHistoryPage employeeTransactions__tableTitle row">
        <div class="col-3">
          <div>{{ $t("employeeCalendarService") }}</div>
        </div>
        <div class="col-3">
          <div>{{ $t("bookingDate") }}</div>
        </div>
        <div class="col-2">
          <div>{{ $t("billingDate") }}</div>
        </div>
        <div class="col-2">
          <div>{{ $t("paymentMethod") }}</div>
        </div>
        <div class="col-1">
          <div>{{ $t("price") }}</div>
        </div>
      </div>
      <employees-transactions-table
        v-for="transaction in transactionsHistory"
        :key="transaction.id"
        :transaction-information="transaction"
      />
      <div
        v-if="transactionsHistory.length === 10"
        class="w-max-content m-auto"
      >
        <base-button class="loadMoreBtn" @click="loadMore()" :load="true"
          >{{ $t("loadMore") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import CartViewIcon from "@/components/UI/icons/buttonIcons/CartViewIcon.vue";
import TableViewIcon from "@/components/UI/icons/TableViewIcon";
import ResetIcon from "@/components/UI/icons/filterIcons/ResetIcon";
import VueMultiselect from "vue-multiselect";
import DataDay from "@/components/components-helpers/DataDay";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import Datepicker from "@vuepic/vue-datepicker";
import { mapGetters } from "vuex";
import { isSameMonth, set, startOfMonth } from "date-fns";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import EmployeesTransactionsTable from "@/components/elements/transactions/EmployeesTransactionsTable";

export default {
  name: "TransactionsEmployeePage",
  components: {
    EmployeesTransactionsTable,
    HorizontalArrow,
    DataFormatted,
    DataDay,
    ResetIcon,
    TableViewIcon,
    CartViewIcon,
    BaseButton,
    VueMultiselect,
    Datepicker,
  },
  data() {
    return {
      showTransactions: false,
      statisticData: { start: startOfMonth(new Date()), end: new Date() },
      statisticDataRange: [],
      statistics: [],
      servicesOptions: [],
      filterByServices: [],
      filter: {
        currentMonth: true,
        customData: false,
      },
      view: {
        cart: false,
        table: true,
      },
      transactionsHistory: [],
      offset: 0,
      limit: 10,
    };
  },
  mounted() {
    this.response();
  },
  methods: {
    async response() {
      this.transactionsHistory = await this.getTransactions();
      this.servicesOptions = await this.getServicesList();
      this.statistics = await this.getStatistics();
    },
    async setCurrentMonth() {
      this.statisticData.start = set(startOfMonth(new Date()), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      this.statisticData.end = set(new Date(), {
        hours: 23,
        minutes: 59,
        seconds: 59,
      });
      this.statisticDataRange = [];
      this.statistics = await this.getStatistics(
        this.filterByServices,
        this.statisticData.start,
        this.statisticData.end
      );
    },
    async setCustomDateRange() {
      if (this.statisticDataRange[0] && this.statisticDataRange[1]) {
        this.statisticData.start = set(new Date(this.statisticDataRange[0]), {
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        this.statisticData.end = set(new Date(this.statisticDataRange[1]), {
          hours: 23,
          minutes: 59,
          seconds: 59,
        });
        this.statistics = await this.getStatistics(
          this.filterByServices,
          this.statisticData.start,
          this.statisticData.end
        );
        this.filterBy("customData");
      } else {
        this.filterBy("currentMonth");
      }
    },
    changeView(view) {
      for (let i in this.view) {
        this.view[i] = i === view;
      }
    },
    resetFilter() {
      this.filterByServices = null;
      this.filterBy("currentMonth");
    },
    filterBy(key) {
      for (let i in this.filter) {
        this.filter[i] = key === i;
      }
      if (key === "currentMonth") {
        this.setCurrentMonth();
      }
    },
    async getHistory() {
      return this.$http.get("/appointments/recent").then(({ data }) => {
        return data.body;
      });
    },
    async getServicesList() {
      return this.$http.get("/services").then(({ data }) => {
        return data.body;
      });
    },
    async getTransactions() {
      return this.$http
        .get("/appointment-transactions/employee", {
          params: {
            offset: this.offset,
            limit: this.limit,
          },
        })
        .then(({ data }) => {
          return data.body;
        });
    },
    async getStatistics(serviceFilter, dataStart, dataEnd) {
      let service = serviceFilter ? serviceFilter.id : null;
      let start = dataStart ? dataStart : null;
      let end = dataEnd ? dataEnd : null;
      return this.$http
        .get(`/statistics/employee/transactions`, {
          params: {
            service: service,
            start: start,
            end: end,
          },
        })
        .then(({ data }) => {
          return data.body;
        });
    },
    loadMore() {
      this.limit = this.limit + 10;
      this.getEmployees(this.search, this.filterPosition.id);
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
    thisMonth() {
      return isSameMonth(
        new Date(this.statisticData.start),
        new Date(this.statisticData.end)
      );
    },
  },
  watch: {
    filterByServices: {
      deep: true,
      async handler(value) {
        this.statistics = await this.getStatistics(
          value,
          this.statisticData.start,
          this.statisticData.end
        );
      },
    },
  },
};
</script>

<style></style>
