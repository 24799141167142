<template>
  <h1>{{ $t("sidebarTransactionsBtn") }}</h1>
  <div class="row companyTransactionCart__title">
    <div class="col-3">
      <div>{{ $t("transactionDate") }}</div>
    </div>
    <div class="col-3">
      <div>{{ $t("coverageSubscription") }}</div>
    </div>
    <div class="offset-1 col-2">
      <div>{{ $t("totalPaid") }}</div>
    </div>
    <div class="offset-1 col-2">
      <div>{{ $t("Status") }}</div>
    </div>
  </div>
  <company-transaction-cart
    v-for="transaction in transactions"
    :key="transaction.id"
    :transaction="transaction"
  />
</template>

<script>
import CompanyTransactionCart from "@/components/elements/transactions/CompanyTransactionCart";

export default {
  name: "TransactionsCompanyPage",
  components: { CompanyTransactionCart },
  data() {
    return {
      transactions: [],
    };
  },
  mounted() {
    this.getTransactions();
  },
  methods: {
    getTransactions() {
      this.$http.get("/company-transactions/company", {}).then(({ data }) => {
        this.transactions = data.body;
      });
    },
  },
};
</script>

<style></style>
