<template>
  <router-link :to="/company-transaction/ + transactionInformation.id">
    <div class="transactionCart row">
      <div class="col-2">
        <div class="transactionCart_position">
          {{ transactionInformation.company.name }}
        </div>
        <div class="transactionCart_expertName">
          {{ transactionInformation.company.code }}
        </div>
      </div>
      <div class="col-2">
        <div class="transactionCart_time">
          <data-formatted
            v-if="transactionInformation.date_paid"
            date-format="HH:mm"
            :data="transactionInformation.date_paid"
          />
        </div>
        <div class="transactionCart_date">
          <data-day
            v-if="transactionInformation.date_paid"
            :data="transactionInformation.date_paid"
          />
        </div>
      </div>
      <div class="col-2">
        <div class="transactionCart_status">
          €{{ transactionInformation.total }}
        </div>
      </div>
      <div class="col-1">
        <div class="transactionCart_coast">
          {{ transactionInformation.status.name }}
        </div>
      </div>
      <div class="col-1 text-end">
        <arrow height="16px" width="16px" />
      </div>
    </div>
  </router-link>
</template>

<script>
import Arrow from "@/components/UI/icons/arrows/Arrow";
import DataDay from "@/components/components-helpers/DataDay";
import CompanyLogo from "@/components/UI/images/CompanyLogo";
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  components: { CompanyLogo, DataFormatted, DataDay, Arrow },
  props: {
    transactionInformation: {},
  },
  name: "TransactionsCartCompany",
};
</script>

<style></style>
