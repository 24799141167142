<template>
  <router-link :to="/transaction/ + transactionInformation.id">
    <div class="row transactionCart employeeTransactions">
      <div class="col-3">
        <div class="employeeTransactions__firstRow">
          {{ transactionInformation.consultation_name }},
          <duration-time :data="transactionInformation.duration" />
        </div>
        <div class="employeeTransactions__secondRow">
          {{ transactionInformation.service_name }}
        </div>
      </div>
      <div class="col-3">
        <div class="employeeTransactions__firstRow">
          <data-formatted
            v-if="!!transactionInformation.order_date"
            date-format="HH:mm"
            :data="new Date(transactionInformation.order_date)"
          />
        </div>
        <div class="employeeTransactions__secondRow">
          <data-formatted
            v-if="!!transactionInformation.order_date"
            date-format="dd MMM yyyy"
            :data="new Date(transactionInformation.order_date)"
          />
        </div>
      </div>
      <div v-if="!!transactionInformation.payment_date" class="col-2">
        <div class="employeeTransactions__firstRow">
          <data-formatted
            date-format="HH:mm"
            :data="new Date(transactionInformation.payment_date)"
          />
        </div>
        <div class="employeeTransactions__secondRow">
          <data-formatted
            date-format="dd MMM yyyy"
            :data="new Date(transactionInformation.payment_date)"
          />
        </div>
      </div>
      <div v-else class="col-2">
        <div class="employeeTransactions__firstRow">
          <data-formatted
            date-format="HH:mm"
            :data="new Date(transactionInformation.invoice_sending_date)"
          />
        </div>
        <div class="employeeTransactions__secondRow">
          <data-formatted
            date-format="dd MMM yyyy"
            :data="new Date(transactionInformation.invoice_sending_date)"
          />
        </div>
      </div>
      <div class="col-2">
        <div class="employeeTransactions__coast">
          {{ transactionInformation.payment_method }}
        </div>
      </div>
      <div class="col-1">
        <div
          class="recentAppointmentsEmployeeTable__status"
          :class="{
            'recentAppointmentsEmployeeTable__status-fail': statusState(
              transactionInformation.status
            ),
          }"
        >
          {{ transactionInformation.status }}
        </div>
      </div>
      <div class="col-1 text-end">
        <arrow height="16px" width="16px" />
      </div>
    </div>
  </router-link>
</template>

<script>
import Arrow from "@/components/UI/icons/arrows/Arrow";
import DurationTime from "@/components/components-helpers/DurationTime";
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  props: {
    transactionInformation: {},
  },
  name: "employeesTransactionsTable",
  components: { DataFormatted, DurationTime, Arrow },
  methods: {
    statusState(status) {
      return status === "cancelled" || status === "failed";
    },
  },
};
</script>

<style></style>
