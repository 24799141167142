<template>
  <h1>{{ $t("sidebarTransactionsBtn") }}</h1>
  <div class="d-flex justify-content-start">
    <div
      class="transactionsPage__language"
      :class="{ 'transactionsPage__language-active': employees }"
      @click="
        employees = true;
        companies = false;
      "
    >
      {{ $t("sidebarEmployeesBtn") }}
    </div>
    <div
      class="transactionsPage__language"
      :class="{ 'transactionsPage__language-active': companies }"
      @click="
        companies = true;
        employees = false;
      "
    >
      {{ $t("sidebarCompanyBtn") }}
    </div>
  </div>
  <div v-if="employees">
    <div class="row transactionsPage__tableTitles">
      <div class="col-4">{{ $t("transactionsEmployee") }}</div>
      <div class="col-3">{{ $t("serviceExpert") }}</div>
      <div class="col-3">{{ $t("transactionDate") }}</div>
      <div class="col-1">{{ $t("coastStatus") }}</div>
    </div>
    <transaction-cart
      v-for="transaction in transactions.employee"
      :key="transaction.id"
      :transaction-information="transaction"
    />
    <div
      v-if="transactions.employee.length === 10"
      class="w-max-content m-auto"
    >
      <base-button
        class="loadMoreBtn"
        @click="loadMore('employees')"
        :load="true"
        >{{ $t("loadMore") }}
      </base-button>
    </div>
  </div>
  <div v-if="companies">
    <div class="row transactionsPage__tableTitles">
      <div class="col-3">{{ $t("Company") }}</div>
      <div class="col-3">{{ $t("transactionDate") }}</div>
      <div class="col-3">{{ $t("Cost") }}</div>
      <div class="col-1">{{ $t("Status") }}</div>
    </div>
    <transactions-cart-company
      v-for="transaction in transactions.companies"
      :key="transaction.id"
      :transaction-information="transaction"
    />
    <div
      v-if="transactions.companies.length === 10"
      class="w-max-content m-auto"
    >
      <base-button
        class="loadMoreBtn"
        @click="loadMore('companies')"
        :load="true"
        >{{ $t("loadMore") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import TransactionsCartCompany from "@/components/elements/transactions/TransactionsCartCompany";
import TransactionCart from "@/components/elements/transactions/TransactionCart";
import MainLayout from "@/layouts/MainLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";

export default {
  components: {
    BaseButton,
    TransactionsCartCompany,
    TransactionCart,
    MainLayout,
  },
  name: "TransactionsAdminPage",
  data() {
    return {
      transactions: {
        employee: {},
        companies: {},
      },
      employees: true,
      companies: false,
      limit: {
        companies: 10,
        employees: 10,
      },
    };
  },
  mounted() {
    this.getEmployeeTransactions();
    this.getCompanyTransactions();
  },
  methods: {
    getEmployeeTransactions() {
      this.$http
        .get("/appointment-transactions", {
          params: {
            limit: this.limit.employees,
          },
        })
        .then(({ data }) => {
          this.transactions.employee = data.body;
        });
    },
    getCompanyTransactions() {
      this.$http
        .get("/company-transactions", {
          params: {
            limit: this.limit.companies,
          },
        })
        .then(({ data }) => {
          this.transactions.companies = data.body;
        });
    },
    loadMore(key) {
      this.limit[key] = this.limit[key] + 10;
      if (key === "companies") this.getCompanyTransactions();
      if (key === "employees") this.getEmployeeTransactions();
    },
  },
};
</script>

<style></style>
