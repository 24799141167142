<template>
  <div class="position-relative">
    <picture v-if="imageName">
      <source type="image/webp" :srcset="`${imagePath}.webp`" />
      <img
        class="companyLogo"
        :class="{ companyLogo__companyPage: companyPage }"
        :src="`${imagePath}.png`"
        :alt="company_slug + 'company logo'"
      />
    </picture>
    <img
      v-else
      :class="{ companyLogo: thumbnail }"
      src="@/styles/img/default/company.svg"
      alt="default company logo"
    />
    <div class="companyLogo-hovering-wrapper">
      <svg
        class="avatar-hovering"
        width="43"
        height="35"
        viewBox="0 0 43 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6665 0L12.7498 5.8334H5.74984C2.84127 5.8334 0.5 8.17478 0.5 11.0833V29.7501C0.5 32.6587 2.84138 35 5.74984 35H37.2502C40.1587 35 42.5 32.6586 42.5 29.7501V11.0833C42.5 8.17468 40.1586 5.8334 37.2502 5.8334H30.2502L27.3335 0H15.6665ZM21.4999 12.2501C26.0105 12.2501 29.6664 15.9066 29.6664 20.4167C29.6664 24.9273 26.0099 28.5833 21.4999 28.5833C16.9899 28.5833 13.3334 24.9268 13.3334 20.4167C13.3334 15.9061 16.9899 12.2501 21.4999 12.2501Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageName: String,
    company: Boolean,
    companyPage: false,
    companyCreatePage: false,
    company_slug: String,
    size: String,
    thumbnail: false,
  },
  name: "CompanyLogo",
  data() {
    return {
      path: process.env.VUE_APP_ORIGIN_UPLOADS_PATH,
    };
  },
  computed: {
    imagePath() {
      if (this.companyCreatePage) {
        return `${this.path}temp/${this.imageName}`;
      } else {
        return `${this.path}/companies/${this.company_slug}/${this.imageName}-${this.size}`;
      }
    },
  },
};
</script>

<style></style>
